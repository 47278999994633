import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { URL } from 'utilities/consts';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { ButtonsView } from 'utilities/components/buttons/component/view';
import { Img } from 'utilities/components/img/component';

import './view.scss';

SwiperCore.use([Navigation]);

export const CollaborationView = ({ companies }) => (
    <section className="collaboration">
        <HeadlineView text="Współpracujemy z najlepszymi" />
        {
            companies?.length
            && (
                <Swiper
                    className="collaboration__carousel"
                    navigation={{
                        nextEl: '.collaboration--next',
                        prevEl: '.collaboration--previous'
                    }}
                    loop
                    slidesPerView="1"
                    breakpoints={{
                        360: {
                            slidesPerView: 2
                        },
                        600: {
                            slidesPerView: 3
                        },
                        1024: {
                            slidesPerView: 4
                        },
                        1280: {
                            slidesPerView: 5
                        },
                        1600: {
                            slidesPerView: 6
                        }
                    }}
                    centerInsufficientSlides
                    centeredSlides
                    loopAdditionalSlides="1"
                >
                    {
                        companies?.map((currentValue, index) => (
                            <SwiperSlide className="carousel__item" key={ index }>
                                <a className="item__link" rel="noreferrer" target="_blank" href={ currentValue.url }>
                                    <Img className="item__logo" src={ `${URL}/${currentValue?.image}` } alt={ currentValue?.alt_image } />
                                </a>
                            </SwiperSlide>
                        ))
                    }
                    <ButtonsView name="collaboration" />
                </Swiper>
            )
        }
    </section>
);

CollaborationView.propTypes = {
    companies: PropTypes.instanceOf(Array).isRequired
};