import React from 'react';
import PropTypes from 'prop-types';
import { URL } from 'utilities/consts';
import { Img } from 'utilities/components/img/component';

import './view.scss';

export const AdvantagesView = ({ advantages }) => (
    <section className="advantages">
        {
            advantages && advantages.map((currentValue, index) => (
                <div className="advantages__advantage" key={ index }>
                    <Img className="advantage__image" src={ `${URL}/${currentValue.image}` } alt={ currentValue.name } />
                    <div>
                        {
                            (currentValue.icon)
                                && (<Img className="advantage__logo" src={ `${URL}/${currentValue.icon}` } alt={ currentValue.alt_icon } />)
                        }
                        {
                            (index === 1)
                                ? (<h1 className="advantage__headline">{ currentValue.name }</h1>)
                                : (<h4 className="advantage__headline">{ currentValue.name }</h4>)
                        }
                        <p className="advantage__description">{ currentValue?.content }</p>
                        <p className="advantage__description">{ currentValue?.content_2 }</p>
                        <p className="advantage__description">{ currentValue?.content_3 }</p>
                    </div>
                </div>
            ))
        }
    </section>
);

AdvantagesView.propTypes = {
    advantages: PropTypes.instanceOf(Array).isRequired
};