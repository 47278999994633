import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

import { ButtonsView } from 'utilities/components/buttons/component/view';
import { Img } from 'utilities/components/img/component';

import quotationMark from 'assets/images/quality/quotation-mark.svg';
import chair from 'assets/images/quality/chair.png';

import './view.scss';

SwiperCore.use([Navigation]);

export const ReferencesView = ({ references }) => (
    <section className="references">
        <Img className="references__image" src={ chair } alt="krzesło" />
        <div className="references__container">
            <h4 className="references__headline">Klienci o nas</h4>
            <Img className="references__icon" src={ quotationMark } alt="cudzysłów" />
            <Img className="references__icon" src={ quotationMark } alt="cudzysłów" />
            <Swiper
                className="references__carousel"
                navigation={{
                    nextEl: '.references--next',
                    prevEl: '.references--previous'
                }}
                loop
            >
                {
                    references.map((currentValue, index) => (
                        <SwiperSlide className="carousel__item" key={ index }>
                            <p className="item__text">{ currentValue?.content }</p>
                            <p className="item__author">{ currentValue?.author }</p>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
        <ButtonsView name="references" />
    </section>
);

ReferencesView.propTypes = {
    references: PropTypes.instanceOf(Array).isRequired
};