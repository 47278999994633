import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { CollaborationView } from './view';

export const Collaboration = () => {
    const data = useStaticQuery(graphql`
    query GetAllCompany {
            allCompany(filter: {is_active: {eq: 1}}) {
              nodes {
                alt_image
                image
                is_active
                name
                url
              }
        }
      }
    `);

    return (<CollaborationView companies={ data?.allCompany?.nodes } />);
};