import React from 'react';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { Img } from 'utilities/components/img/component';

import person from 'assets/images/quality/person.svg';
import bulb from 'assets/images/quality/bulb.svg';
import like from 'assets/images/quality/like.svg';
import design from 'assets/images/quality/design.svg';

import './view.scss';

export const ValuesView = () => (
    <section className="values">
        <HeadlineView text="Nasze wartości" />
        <div className="values__container">
            <div className="values__value">
                <Img className="value__icon" src={ person } alt="ikona" />
                <h4 className="value__headline">Klient końcowy</h4>
                <p className="value__description">Myślimy o użytkownikach naszych produktów. Ich zadowolenie z produktów Barański Drzwi jest dla nas kluczowe. Posiadamy szeroką ofertę, zdolną zaspokoić każde wymagania. W razie problemów z produktem, nawet po upływie gwarancji, reagujemy błyskawicznie i nie zostawiamy klienta bez pomocy.</p>
            </div>
            <div className="values__value">
                <Img className="value__icon" src={ bulb } alt="ikona" />
                <h4 className="value__headline">Innowacyjność</h4>
                <p className="value__description">Nieustannie rozwijamy nasze produkty. Poszukujemy nowych rozwiązań i wykorzystujemy nowoczesne technologie, by podnosić jakość naszej oferty. Od lat pozostajemy w awangardzie innowacji w naszej branży.</p>
            </div>
            <div className="values__value">
                <Img className="value__icon" src={ like } alt="ikona" />
                <h4 className="value__headline">Niezawodność</h4>
                <p className="value__description">Przykładamy uwagę do detali. Dbamy o to, by każdy produkt opuszczający naszą fabrykę był dopracowany w 100%.</p>
            </div>
            <div className="values__value">
                <Img className="value__icon" src={ design } alt="ikona" />
                <h4 className="value__headline">Design</h4>
                <p className="value__description">Funkcjonalność tworzonych drzwi jest dla nas niezwykle istotna, ale nie mniej ważna jest estetyka. Eksperymentujemy z wzorami, wprowadzamy do oferty nowe materiały. Projektujemy drzwi w rozmaitym designie: klasyka, modernizm, boho - żadna stylistyka nie jest nam obca. Tworzymy rozwiązania unikalne w skali rynku.</p>
            </div>
        </div>
    </section>
);