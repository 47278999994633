import React from 'react';

import { Navigation } from 'utilities/components/navigation/component';
import { Advantages } from 'features/quality/advantages/component';
import { Collaboration } from 'features/quality/collaboration/component';
import { References } from 'features/quality/references/component';
import { ValuesView } from 'features/quality/values/component/view';
import { News } from 'utilities/components/news';
import { Footer } from 'utilities/components/footer/component';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import 'features/quality/viev.scss';
import SEO from 'utilities/components/seo';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

const Quality = () => {
    const breadcrumbs = [routes.home, routes.jakosc];

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };
    const { metaTitle, metaDescription, description } = useSeoTexts('Jakość');

    return (
        <main className="quality">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Jakość Barańskiego - Naszą pasją są drzwi' }
                description={
                    metaDescription
                    || 'Sprawdź jakość i innowacyjność naszych produktów. Zobacz jak dbamy o środowisko naturalne. Poznaj markę Barański!'
                }
                image="https://d3mtmn4lo37cs8.cloudfront.net/files/Jakosc_Baranskiego/drewno_to_nasza_pasja.jpg"
                alt="Jakość Barańskiego"
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <Advantages />
            <Collaboration />
            <References />
            <ValuesView />
            <News headline="Aktualności" />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Quality;
