import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { AdvantagesView } from './view';

export const Advantages = () => {
    const advantages = useStaticQuery(graphql`
        query AllCompanyQualities {
            allCompanyQualities {
                nodes {
                    data {
                        alt_image
                        alternative_id
                        content
                        content_2
                        content_3
                        name
                        image
                        alt_icon
                        icon
                    }
                }
            }
        }
    `);

    return (<AdvantagesView advantages={ advantages?.allCompanyQualities?.nodes[0]?.data } />);
};